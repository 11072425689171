<template>
  <div id="manageOSP" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Certification Validation</div>
      </div>
    </div>
    <div id="boxSearch" class="wrap-Main">
      <div class="box-S4">
        <v-text-field
          class="hideMessage"
          v-model="search"
          append-icon="mdi-account-search"
          label="Search"
        ></v-text-field>
      </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :search="search" :items="UserItem">
          <template v-slot:item.u_imageurl="{ item }">
            <img class="B-display In-table" :src="item.u_imageurl" />
          </template>

          <template v-slot:item.FullName="{ item }">
            {{ item.FullName }}
            <div class="red--text">
              <v-icon
                class="subheading"
                v-show="wranningSafetyTraining(item.SafetyTrainingDayLeft)"
                color="red"
                >mdi-clock-alert</v-icon
              >
              {{ wranningSafetyTraining(item.SafetyTrainingDayLeft) }}
            </div>

            <div class="red--text">
              <v-icon
                class="subheading"
                v-show="wranningMedicalCheckup(item.MedicalCheckupDayLeft)"
                color="red"
                >mdi-clock-alert</v-icon
              >
              {{ wranningMedicalCheckup(item.MedicalCheckupDayLeft) }}
            </div>
          </template>

          <template v-slot:item.u_safety_training="{ item }">
            <span v-if="item.u_safety_training != null">
              {{ item.u_safety_training | yyyyMMddHHmm }}
            </span>
          </template>

          <template v-slot:item.u_medical_checkup="{ item }">
            <span v-if="item.u_medical_checkup != null">
              {{ item.u_medical_checkup | yyyyMMddHHmm }}
            </span>
          </template>
        </v-data-table>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { PdfLineJoin } from "@syncfusion/ej2-pdf-export";
import { format, addDays, startOfDay, differenceInDays } from "date-fns";
import locale from "date-fns/locale/th";
import fillerdate from "../utils/filterdate";
export default {
  data: () => ({
    userdata: null,
    search: "",
    deleteItem: 0,
    dialogDelete: false,
    loading: false,
    UserItem: [],
    total: 0,
    headers: [
      { value: "u_imageurl", text: "", sortable: false },
      // { value: "u_staffcode", text: "Staff Code", sortable: true }, //comment if no data
      // { value: "u_firstname", text: "Name", sortable: true },
      //Edit
      { value: "FullName", text: "FullName", sortable: false },
      { value: "u_telephone", text: "Telephone", sortable: false },
      { value: "u_department", text: "Department", sortable: true },
      { value: "u_jobposition", text: "Position", sortable: false },
      { value: "c_name", text: "Company", sortable: false },
      { value: "u_safety_training", text: "Safety Training", sortable: true },
      { value: "u_medical_checkup", text: "Medical Check up", sortable: true },
    ],
  }),
  computed: {},
  filters: {
    // date: createDateFilter("DD/MM/YYYY", { locale })
  },
  mounted: function () {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    this.renderUI();
  },

  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderUI() {
      try {
        //User
        const { user } = await feathersClientUOA.get("authentication");
        this.search = "";
        const q = {};

        q.r_id = 3;
        //Edit
        //Flight เห็นหมดทุกคนสามารถขึ้นได้
        if (this.userdata.r_id != 4) {
          q.r_id = 3;
          //User ยังไม่มีข้อมูล o_id
          q.o_id = this.userdata.o_id;
        }

        // q.Owner = user.Owner;
        var today = new Date();
        var result = addDays(new Date(), +90);
        var start = startOfDay(result);

        //Add start Add 90 day
        var startAdd90Day = startOfDay(result).getTime();
        var res = await feathersClientUOA
          .service("viewuser")
          .find({ query: q });


        this.total = res.total;
        this.UserItem = res.data;
      } catch (err) {
        alert("ไม่สามารถต่อ server ได้");
      }
    },

    async GotoResume(Id) {
      this.$router.push({
        name: "ShoreUserCreateEdit",
        params: { mode: "edit", Id: Id },
      });
    },
    wranningMedicalCheckup(MedicalCheckupDayLeft) {
      if (MedicalCheckupDayLeft != null) {
        if (MedicalCheckupDayLeft <= 90 && MedicalCheckupDayLeft >= 1) {
          var MedicalCheckupWranning =
            "MedicalCheckup Less than " + MedicalCheckupDayLeft + " days.";

          return MedicalCheckupWranning;
        } else if (MedicalCheckupDayLeft <= 0) {
          var MedicalCheckupExpired = "MedicalCheckup Expired";

          return MedicalCheckupExpired;
        }
      } else if (MedicalCheckupDayLeft == null) {
        var noMedicalCheckup = "No MedicalCheckup Date";

        return noMedicalCheckup;
      }
    },
    wranningSafetyTraining(SafetyTrainingDayLeft) {
      if (SafetyTrainingDayLeft != null) {
        if (SafetyTrainingDayLeft <= 90 && SafetyTrainingDayLeft >= 1) {
          var SafetyTrainingWranning =
            "SafetyTraining Less than " + SafetyTrainingDayLeft + " days.";

          return SafetyTrainingWranning;
        } else if (SafetyTrainingDayLeft <= 0) {
          var SafetyTrainingExpired = "SafetyTraining Expired";

          return SafetyTrainingExpired;
        }
      } else if (SafetyTrainingDayLeft == null) {
        var noSafetyTraining = "No SafetyTraining Date";

        return noSafetyTraining;
      }
    },
  },
};
</script>